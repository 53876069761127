(function () {
  'use strict';

  function runBlock($rootScope, $state, User, $mdSidenav, $document, $anchorScroll, $mdToast, ActionCableConfig, env, Utils) {
    const rootScope = $rootScope,
        root = $document[0];
    ActionCableConfig.autoStart = false;
    rootScope.isMobile = Utils.isMobile();
    rootScope.isIOS = Utils.isIOS();
    User.setRootScopeUser();
    /*
     * Valid injections for state change
     * event, toState, toParams, fromState, fromParams
     * */
    rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
      $anchorScroll('body');
      $rootScope.fromState = fromState;
      $rootScope.fromParams = fromParams;

      if ($document[0].getElementsByTagName('md-sidenav').length) {
        $mdSidenav('left').close();
      }
      if (toState.authenticate && angular.isUndefined(rootScope.user) || toState.data && toState.data.authenticate && angular.isUndefined(rootScope.user)) {
        $state.go('home');
        event.preventDefault();
      }
      if (angular.isDefined(rootScope.user)) {
        ActionCableConfig.wsUri = `${env.wss}?token=${rootScope.user.token}`;
      }
      if (toState.roles && angular.isDefined(rootScope.user) && toState.roles.indexOf(rootScope.user.role) === -1) {
        if (fromState && fromState.name) {
          $state.go(fromState.name);
        } else {
          $state.go('dashboard.index');
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent('Sorry, you are not authorized to see this view.')
            .position('bottom right')
            .hideDelay(3000)
        );
        event.preventDefault();
      }
    });
    rootScope.$on('$stateChangeSuccess', () => {
      const isDashBoard = $state.$current.parent.self.name === 'dashboard',
          html = root.getElementsByTagName('html')[0];
      if (isDashBoard) {
        html.classList.add('is-dashboard');
      } else {
        html.classList.remove('is-dashboard');
      }
    });
  }

  /**
   * @ngdoc service
   * @name bioscann.constant:env
   *
   * @description
   *
   */
  angular
    .module('bioscann')
    .run(runBlock);
}());
